//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo-accessibuild.svg"
import IconYoutube from "../../assets/icon-youtube.svg"
import IconTwitter from "../../assets/icon-twitter.svg"
import IconLinkedIn from "../../assets/icon-linkedin.svg"

const Header = ({ siteTitle }) => (
  <div class="wrapper">
    {" "}
    <header id="top" class="header">
      <div class="branding">
        <h1 style={{ margin: 0 }}>{siteTitle}</h1>
        <div class="logo">
          <img src={Logo} alt="accessibuild logo" />
        </div>
      </div>
      <div class="social">
        <a href="https://www.linkedin.com/company/accessibuild/">
          <IconLinkedIn />
        </a>
        <a href="https://twitter.com/accessibuild">
          <IconTwitter />
        </a>
        <a href="https://youtu.be/NPwvNm6V8OU">
          <IconYoutube />
        </a>
      </div>
    </header>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
