/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Scroll from "./scroll"
import Header from "./header"
import "./layout.css"
import "./sass/manifest.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div class="container">
        <main>{children}</main>

        <Scroll
          showBelow={1000}
          css="position: fixed; right: 3rem; bottom: 5em;"
          class="up"
        />
        <footer class="wrapper">© {new Date().getFullYear()}, Y4U Technologies Inc.</footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
